<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>          
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Disposisi Masuk Pimpinan</b>
            </div>
            <!-- <div class="col-md-2 col-12" v-if="canSeeAll">
              <select class="pull-right form-control" v-model="subject" @change="getFor">
                <option value="me">Diri Sendiri</option>
                <option value="all">Semua</option>
              </select>
            </div> -->
          </div>                  
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :forbidDetail="forbidRead" 
              :forbidDelete="true" 
              :overideSearch="search"
							@onSearch="onSearch"        
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <h3>{{ this.$store.state.dispositionInSupervisor.items.items }}</h3> -->
  </div>
</template>
<script>
import Table from "../../../components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import axios from "axios";

export default {
  components: {
    RotateSquare5,
    Table
  },
  data() {
    let last = localStorage.getItem('last_request');
    last = last != null ?last.split('?'):',?,'.split('?');
    let params = {};
    if(last[0].includes('disposition_in_supervisor')){
        params = JSON.parse(last[1]);
    }
    return {
      search:params.search||'',
      subject : 'all',
      is_leader : '',
      headers: {
        subject: {
          label: "Perihal",
          sortable: true
        },  
        agenda_number: {
          label: "Nomor Agenda",
          sortable: true
        },
        nomor_surat: {
          label: "Nomor Surat",
          sortable: true
        },
        unit_name: {
          label: "Asal Surat",
          sortable: false
        },
              
        send_dispo: {
          label: "Tanggal Dispo Diterima",
          sortable: true,
          type:"dateTime"
        },
        catatan: {
          label: "Catatan",
          sortable: true
        },
        jenis_dispo: {
          label: "Jenis Disposisi",
          sortable: true
        },                     
        is_cancel: {
          label: "Status Aktif",
          sortable: false
        },
        is_read: {
          label: "Dibaca",
          sortable: false,
          is_read: true
        },
      }
    };
  },
  computed: {
    items() {
      let data = [];
      if(this.$store.state.dispositionInSupervisor.items!==undefined){
        if(this.$store.state.dispositionInSupervisor.items.items!==undefined){
          if(!Array.isArray(this.$store.state.dispositionInSupervisor.items.items) && this.$store.state.dispositionInSupervisor.items.items!==undefined){
            data = Object.keys(this.$store.state.dispositionInSupervisor.items.items).map(i => this.$store.state.dispositionInSupervisor.items.items[i]);
          }else{
            data = this.$store.state.dispositionInSupervisor.items.items;
          }
        }
      }
      let newData = [];
      for (let index = 0; index < data.length; index++) {
        let element = data[index]; 
        const jenis_dispo = '';    
        const send_dispo = '';    
        if(element.is_recipient == 1 && element.is_personal == 0){
          jenis_dispo = 'Struktural';
        } else if(element.is_recipient == 0 && element.is_personal == 1){
          jenis_dispo = 'Personal';
        } else if(element.is_recipient == 1 && element.is_personal == 1){
          if(element.is_leader == 1){
            jenis_dispo = 'Struktural & Personal';
          } else {
            jenis_dispo = 'Personal';
          }
        } 

        if(element.send_recipient != '0'){
          send_dispo = element.send_recipient;
        } else if(element.send_personal){
          send_dispo = element.send_personal;
        } else {
          send_dispo = '';
        } 
        
        newData[index] = {
          id:element.id,
          agenda_number:element.agenda_number,
          nomor_surat:element.nomorsurat,
          subject:element.subject,
          sifat:element.sifat,
          is_cancel : element.is_cancel == 0 ? (element.is_false?"Salah kirim":"Aktif") : "Dibatalkan",
          is_read:element.is_read,
          is_read_recipient:element.is_read_recipient,
          is_read_personal:element.is_read_personal,
          isBold:(element.is_read_personal==0 && element.is_read_recipient==0)?true:false,
          catatan:element.note,
          document_jenis:element.document_jenis,
          tanggal_diterima:element.tanggal_diterima,
          redaksi:element.redaksi,
          asal_surat:element.document_asrat,
          unit_name:element.unit_name,
          pengirim:element.name,
          profil:element.username,
          jenis_dispo:jenis_dispo,
          send_dispo: send_dispo
        };
      }
      return newData;
    },
    filters() {
      let filt = {};
      if(this.$store.state.dispositionInSupervisor.items!==undefined){
        if(this.$store.state.dispositionInSupervisor.items.filters!==undefined){
          filt = this.$store.state.dispositionInSupervisor.items.filters;
        }
      }
      return filt;
    },
    // items() {
    //   var data = [];      
    //   if(this.$store.state.dispositionInSupervisor.items){
    //     for (let index = 0; index < this.$store.state.dispositionInSupervisor.items.items.length; index++) {
    //       let element = this.$store.state.dispositionInSupervisor.items.items[index]; 
    //       const jenis_dispo = '';    
    //       const send_dispo = '';    
    //       if(element.is_recipient == 1 && element.is_personal == 0){
    //         jenis_dispo = 'Struktural';
    //       } else if(element.is_recipient == 0 && element.is_personal == 1){
    //         jenis_dispo = 'Personal';
    //       } else if(element.is_recipient == 1 && element.is_personal == 1){
    //         jenis_dispo = 'Struktural & Personal';
    //       } 

    //       if(element.send_recipient != ''){
    //         send_dispo = element.send_recipient;
    //       } else if(element.send_personal){
    //         send_dispo = element.send_personal;
    //       } else {
    //         send_dispo = '';
    //       } 
          
    //       data[index] = {
    //         id:element.id,
    //         agenda_number:element.agenda_number,
    //         nomor_surat:element.nomorsurat,
    //         subject:element.subject,
    //         sifat:element.sifat,
    //         is_cancel : element.is_cancel == 0 ? "Aktif" : "Dibatalkan",
    //         is_read:element.is_read,
    //         is_read_recipient:element.is_read_recipient,
    //         is_read_personal:element.is_read_personal,
    //         catatan:element.note,
    //         document_jenis:element.document_jenis,
    //         tanggal_diterima:element.tanggal_diterima,
    //         redaksi:element.redaksi,
    //         asal_surat:element.document_asrat,
    //         unit_name:element.unit_name,
    //         pengirim:element.name,
    //         profil:element.username,
    //         jenis_dispo:jenis_dispo,
    //         send_dispo: send_dispo
    //       };
    //     }
    //   }
    //   return data;
    // },    
    // filters() {
    //   return this.$store.state.dispositionInSupervisor.items ? this.$store.state.dispositionInSupervisor.items.filters: {};
    // },
    state() {
      return this.$store.state.dispositionInSupervisor;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.disposition_in_supervisor){
        return !this.$store.state.profile.permissions.disposition_in_supervisor.update;
      }
      return false;
    },
    canSeeAll(){
      if(this.$store.state.profile.permissions.disposition_in_supervisor_all){
        return this.$store.state.profile.permissions.disposition_in_supervisor_all.read;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.disposition_in_supervisor){
        return !this.$store.state.profile.permissions.disposition_in_supervisor.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.disposition_in_supervisor){
        return !this.$store.state.profile.permissions.disposition_in_supervisor.delete;
      }
      return false;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      search: this.search,
      items: []
    };
    this.$store.commit("dispositionInSupervisor/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val){
			this.search = val
		},
    get(val) {      
      this.$store.dispatch("dispositionInSupervisor/getDispositionInSupervisor", val);
    },
    doDetail(val) {
      this.updateSendStatus(val.id);
      this.$store.dispatch("dispositionInSupervisor/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("dispositionInSupervisor/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("dispositionInSupervisor/submitDelete", val);
    },
    doSend(val) {
      this.$store.dispatch("dispositionInSupervisor/submitSend", val);
    },
    updateSendStatus(id) {      
      axios.get(`/disposition_in_supervisor/read/${id}`).then(response => {});
    },
    getFor(){
      const state = {
        subject: this.subject
      };
      this.$store.commit("dispositionInSupervisor/STATE", state);
      this.get(state);
    }
  }
};
</script>
